function handleProductBoxClick(event) {
    if (window.innerWidth > 768) {
        const url = event.currentTarget.getAttribute('data-url');
        window.location.href = url;
    }
}

const productBoxes = document.querySelectorAll('.product-box');
productBoxes.forEach(function(box) {
    box.addEventListener('click', handleProductBoxClick);
});

function updateClickableState() {
    if (window.innerWidth <= 768) {
        productBoxes.forEach(function(box) {
            box.removeEventListener('click', handleProductBoxClick);
        });
    } else {
        productBoxes.forEach(function(box) {
            box.addEventListener('click', handleProductBoxClick);
        });
    }
}

window.addEventListener('resize', updateClickableState);
updateClickableState();